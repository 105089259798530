export const environment = {
  staging: true,
  production: false,

  apiURL: "https://stagingapi.peliwica.com/api/",
  imageURL: "https://stagingapi.peliwica.com/",
  ADDRESSLOOKUP_API_URL: "https://api.getAddress.io/find/",
  ADDRESSLOOKUP_API_KEY: "cIc_-chj3kiI0S4t6jyUFQ27891",
  EQUINE_ID: '5dd8c0bb-b933-4f28-bc70-b5cc47fafb95',
  HHID: '24853ed2-c893-472c-8bec-d040c76cad08',
  CLIENT_WEBSITE_LINK: 'http://insuremyalpaca.co.uk/',
  ALPACA_ID: '6dd8c0bb-b933-4f28-bc70-b5cc47fafb95',
  LIVESTOCK_ID:'7dd8c0bb-b933-4f28-bc70-b5cc47fafb95',
  TINY_MCE_KEY: 'briu0ld3mtddfhbm6derkceaiwdg88jcis824cducuwxe35p',
 ALLIANZ_EQUINE_ID: '8dd8c0bb-b933-4f28-bc70-b5cc47fafb95',
 AJG_ID: '9dd8c0bb-b933-4f28-bc70-b5cc47fafb95',
  AMLINE_ID: '1dd8c0bb-b933-4f28-bc70-b5cc47fafb95',
  FOOTER_LINK: 'https://www.cooperrosedigital.com/',
  AMLINE_API_URL: "https://api.uat.msamlin.socrates.online/",
  AMLINE_CLIENT_ID: 'peliwica-api',
  AMLINE_CLIENT_SECRET: '8B8CA8A6EC11B1ED9436C27C263B2',
  AMLINE_GRANT_TYPE: 'client_credentials',
  AMLINE_SCOPE: 'horsebox-api',
 
  AMLINE_API_URL2: "https://api.uat.msamlin.socrates.online/",
};